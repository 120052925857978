import {Elastic, gsap, Power2} from 'gsap';
let Flickity = require('flickity')
import 'flickity/dist/flickity.min.css'
import ScrollTrigger from "gsap/ScrollTrigger";
import { wrap } from 'gsap/gsap-core';

export default class ArchiveRealisations {

    constructor(e){
        this.boundClickTravaux = this.clickTravaux.bind(this, e)
        this.boundClickEtudes = this.clickEtudes.bind(this, e)
    }

    start(){
        this.etudesdecasbutton = document.querySelector('#etudesdecas-button');
        this.travauxbutton = document.querySelector('#travaux-button');
        let filter = document.querySelector('.toggle-filter')


        this.flkty = new Flickity('.wrapper-realisations', {
            "dragThreshold": 20,
            "wrapAround": true,
            "pageDots": false,
            "prevNextButtons": false,
            "adaptiveHeight": true,
            on: {
                ready: function() {
                  ScrollTrigger.refresh();
                },
                change: index => {
                    if(index === 0){
                        this.etudesdecasbutton.classList.add('selected');
                        this.travauxbutton.classList.remove('selected');

                        filter.classList.remove('active')

                    }

                    if(index === 1){
                        this.travauxbutton.classList.add('selected');
                        this.etudesdecasbutton.classList.remove('selected');

                        filter.classList.add('active')
                    }
                }
              }
        });

        this.etudesdecasbutton.addEventListener('click', this.boundClickEtudes);
        this.travauxbutton.addEventListener('click', this.boundClickTravaux);

        this.hoverEffect();
        this.filter();

        setTimeout(() => {
            this.flkty.reloadCells()
            ScrollTrigger.refresh()
            console.log('refresh')
        }, 200)

        setTimeout(() => {
            ScrollTrigger.refresh()
        }, 500)

    }

    clickEtudes() {
        this.flkty.select( 0 )
        ScrollTrigger.refresh();
    }

    clickTravaux(){
        this.flkty.select( 1 )
        ScrollTrigger.refresh();
    }

    hoverEffect(){
            let peoples = document.querySelector('.peoples');
            let people = document.querySelectorAll('.people-project');
            const gif = document.querySelector('#gif-project')

            people.forEach((el) => {
                el.addEventListener('mouseover', (e) => {
                    let imageData = e.target.closest('.people-project').getAttribute('data-image');
                    e.target.closest('.people-project').style.zIndex = 99;
                    e.target.closest('.people-project').classList.add('selected');
                    gif.setAttribute('src', imageData)
                    peoples.classList.add('active');

                    gsap.set(gif, {
                        opacity: 0.3,
                        left: e.clientX,
                        top: e.pageY,
                        zIndex: 10
                    });
                });
                el.addEventListener('mousemove', (e) => {
                    gsap.set(gif, {
                        left: e.clientX,
                        top: e.pageY - 70,
                    });
                });
                el.addEventListener('mouseleave', (e) => {
                    gsap.to(gif, {opacity: 0, duration: 0, zIndex: 1});
                    e.target.closest('.people-project').style.zIndex = 1;
                    e.target.closest('.people-project').classList.remove('selected');
                    peoples.classList.remove('active');
                })
            });
    }

    filter(){
        // animation sur l'ouverture du menu
        let filter = gsap
            .timeline()
            .to('.status-open', {opacity: 0, ease: Power2.easeInOut}, 'start')
            .to('.status-closed', {opacity: 1, ease: Power2.easeInOut}, 'start')
            .to('.menu-filter', { translateY: 0, ease: Power2.easeOut, duration: 0.5 }, 'start')
            .staggerTo(
                document.querySelectorAll('.menu-filter .filter-wrapper'),
                0.4,
                {opacity: 1, translateX: 0, ease: Power2.easeIn},
                0.2
            )
            .pause();

        let isOpen = true;
        // ouvrir le menu des filtres
        let tf = document.querySelector('.toggle-filter');

        tf.addEventListener('click', () => {
            run(isOpen)
            isOpen = !isOpen;
        })

        //on initialise les travaux avec la classe "active"
        init();


        // choix d'une catégorie a filtrer
        document.querySelectorAll('.realisation-category').forEach(item => {
            item.addEventListener('click', e => {
                run(isOpen);
                isOpen = !isOpen;

                //on supprime toutes les classes "active" pour reset les catégories
                reset();

                //on filtre les items qui sont dans la catégorie selectionner, sauf si il s'agit de "all"
                let id = e.srcElement.id;
                if(id == 'all')
                    init();
                else
                    filterItem(id);

                /** on raffraichit le ScrollTrigger comme la page a changé de taille */
                this.flkty.reloadCells()

                setTimeout(() => {
                    ScrollTrigger.refresh();
                    console.log('refresh');
                }, 300)
            })
          })

        //lancement de l'anim
        function run(isOpen){
            if(isOpen){
                filter.timeScale(1);
                filter.play()
            }else {
                filter.timeScale(2.5);
                filter.reverse()
            }
        }

        //par defaut, tous les travaux sont visibles
        function init(){
            let realisations = document.getElementsByClassName('items-to-filter');

            for (let i = 0; i < realisations.length; i++){
                realisations[i].classList.add('active');
            }

            //add active to corresponding menu
            let filter = document.querySelector('#all');
            filter.classList.add('active')

            ScrollTrigger.refresh();
        }

        function filterItem(category){
            let realisations = document.getElementsByClassName(category);

            for (let i = 0; i < realisations.length; i++){
                realisations[i].classList.add('active');
            }

            //add active to corresponding menu
            let filter = document.querySelector("#" + category)
            filter.classList.add('active')
        }

        function reset(){
            let realisations = document.getElementsByClassName('items-to-filter');
            for (let i = 0; i < realisations.length; i++){
                if(realisations[i].classList.contains('active')){
                    realisations[i].classList.remove('active');
                }
            }

            //remove active class for every menu
            document.querySelectorAll('.realisation-category').forEach(f => f.classList.remove('active'));
        }
    }

    stop(){
        this.flkty.destroy()
        this.etudesdecasbutton.removeEventListener('click', this.boundClickEtudes)
        this.travauxbutton.removeEventListener('click', this.boundClickTravaux)
    }
}
