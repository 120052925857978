import * as THREE from 'three'
import * as OIMO from 'oimo'
import {gsap, Power2, Expo, Power3, Elastic, TimelineMax, Linear} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


let mobile = window.matchMedia("(max-width: 768px)").matches;

console.log({mobile})

let dev = process.env.NODE_ENV === 'development';
dev = false

if (!dev && !mobile) {
//     let scene = new THREE.Scene();


//     let camera = new THREE.OrthographicCamera(window.innerWidth / -200, window.innerWidth / 200, window.innerHeight / 200, window.innerHeight / -200, -500, 1000);


//     let renderer = new THREE.WebGLRenderer({alpha: true, antialias: true});
//     renderer.setSize(window.innerWidth, window.innerHeight);
//     document.body.appendChild(renderer.domElement);

//     let raycaster = new THREE.Raycaster();

//     let point = new THREE.Vector3(0, 0, 0)

//     let mouse = new THREE.Vector2()
//     let testPlane = new THREE.Mesh(new THREE.PlaneGeometry(50, 50));


//     window.addEventListener('mousemove', (event) => {
//         mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
//         mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

//         raycaster.setFromCamera(mouse, camera);

//         let intersects = raycaster.intersectObjects([testPlane]);

//         if (intersects.length > 0) {
//             point = intersects[0].point
//         }

//     }, false);

//     window.addEventListener('scroll', (e) => {
//         camera.position.y = -window.scrollY / 100;
//     });

//     let world = new OIMO.World({
//         timestep: 1 / 60,
//         iterations: 8,
//         broadphase: 2, // 1 brute force, 2 sweep and prune, 3 volume tree
//         worldscale: 1, // scale full world
//         random: true,  // randomize sample
//         info: false,   // calculate statistic or not
//         gravity: [0, 0, 0]
//     });

//     let color = 0xe0e0e0;

//     let sizeGeometry = Math.min(window.innerWidth/600, 1)
//     console.log({sizeGeometry})
//     let size = sizeGeometry

//     //Cube
//     let geometry = new THREE.BoxBufferGeometry(sizeGeometry, sizeGeometry, sizeGeometry);
//     console.log({geometry})
//     let edges = new THREE.EdgesGeometry(geometry);
//     let cube = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({color}));
//     scene.add(cube);
//     let cubeBody = world.add({
//         type: 'box', // type of shape : sphere, box, cylinder
//         size: [size, size, size], // size of shape
//         pos: [0.1, 0, 0], // start position in degree
//         rot: [0, 0, 90], // start rotation in degree
//         move: true, // dynamic or statique
//         density: 1,
//         friction: 0.2,
//         restitution: 0.2,
//         belongsTo: 1, // The bits of the collision groups to which the shape belongs.
//         collidesWith: 0x00ffffff // The bits of the collision groups with which the shape collides.
//     });

// //Icosahedron
//     geometry = new THREE.IcosahedronBufferGeometry(sizeGeometry);
//     edges = new THREE.EdgesGeometry(geometry);
//     let icosahedron = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({color}));
//     scene.add(icosahedron);
//     let icosahedronBody = world.add({
//         type: 'sphere', // type of shape : sphere, box, cylinder
//         size: [size, size, size], // size of shape
//         pos: [-0.1, 0, 0], // start position in degree
//         rot: [0, 0, 90], // start rotation in degree
//         move: true, // dynamic or statique
//         density: 1,
//         friction: 0.2,
//         restitution: 0.2,
//         belongsTo: 1, // The bits of the collision groups to which the shape belongs.
//         collidesWith: 0x00ffffff // The bits of the collision groups with which the shape collides.
//     });

// //OctahedronBufferGeometry
//     geometry = new THREE.OctahedronBufferGeometry(sizeGeometry);
//     edges = new THREE.EdgesGeometry(geometry);
//     let octa = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({color}));
//     scene.add(octa);
//     let octaBody = world.add({
//         type: 'sphere', // type of shape : sphere, box, cylinder
//         size: [size, size, size], // size of shape
//         pos: [0, 0, 0], // start position in degree
//         rot: [0, 0, 90], // start rotation in degree
//         move: true, // dynamic or statique
//         density: 1,
//         friction: 0.2,
//         restitution: 0.2,
//         belongsTo: 1, // The bits of the collision groups to which the shape belongs.
//         collidesWith: 0x00ffffff // The bits of the collision groups with which the shape collides.
//     });

//     //OctahedronBufferGeometry
//     let sphereBody = world.add({
//         type: 'sphere', // type of shape : sphere, box, cylinder
//         size: [0.5, 0.5, 0.5], // size of shape
//         pos: [0, 0, 0], // start position in degree
//         rot: [0, 0, 90], // start rotation in degree
//         move: true, // dynamic or statique
//         density: 1,
//         friction: 0.2,
//         noSleep: true,
//         restitution: 0.2,
//         belongsTo: 1, // The bits of the collision groups to which the shape belongs.
//         collidesWith: 0x00ffffff // The bits of the collision groups with which the shape collides.
//     });

//     geometry = new THREE.PlaneGeometry(0.1, 10, 32);
//     let material = new THREE.MeshBasicMaterial({color: 0xffff00, side: THREE.DoubleSide});
//     let plane = new THREE.Mesh(geometry, material);
//     plane.position.x = -window.innerWidth / 200;
//     plane.position.y = 0;
//     plane.position.z = 0;
// //scene.add( plane );

//     let offset = 1.0;

// //Walls
//     let ground = world.add({size: [40, 0.1, 40], pos: [0, -(window.innerHeight / 200 * offset), 0]});
//     let top = world.add({size: [40, 0.1, 40], pos: [0, (window.innerHeight / 200 * offset), 0]});

//     let left = world.add({size: [0.1, 40, 40], pos: [-(window.innerWidth / 200 * offset), 0, 0]});
//     let right = world.add({size: [0.1, 40, 40], pos: [(window.innerWidth / 200 * offset), 0, 0]});

//     let front = world.add({size: [40, 40, 0.1], pos: [0, 0, -1.5]});
//     let back = world.add({size: [40, 40, 0.1], pos: [0, 0, 1.5]});

//     window.addEventListener('resize', () => {
//         camera.left = window.innerWidth / -200;
//         camera.right = window.innerWidth / 200;
//         camera.top = window.innerHeight / 200;
//         camera.bottom = window.innerHeight / -200;
//         camera.updateProjectionMatrix();
//         renderer.setSize(window.innerWidth, window.innerHeight);
//     })

//     let animate = function () {
//         requestAnimationFrame(animate);

//         cube.position.copy(cubeBody.getPosition());
//         cube.quaternion.copy(cubeBody.getQuaternion());

//         icosahedron.position.copy(icosahedronBody.getPosition());
//         icosahedron.quaternion.copy(icosahedronBody.getQuaternion());

//         octa.position.copy(octaBody.getPosition());
//         octa.quaternion.copy(octaBody.getQuaternion());

//         //camera.position.y = -window.scrollY / 100;

//         //Mouse
//         sphereBody.awake()
//         sphereBody.setPosition(point);

//         world.step();

//         renderer.render(scene, camera);
//     };

//     animate();
}