import {gsap, Power2, Elastic, TimelineMax} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
let Flickity = require('flickity')
import 'flickity/dist/flickity.min.css'

gsap.registerPlugin(ScrollTrigger);

export default class Blog{
    async start(){
        return new Promise((resolve, reject) => {

            this.flkty = new Flickity('.main-carousel', {
                "wrapAround": false,
                "pageDots": false,
                "prevNextButtons": false,
                "groupCells": 1,
                "cellAlign": 'left',
                "draggable": true,
            });

            resolve();
        })
    }

    stop(){
        this.flkty.destroy()
    }
}
