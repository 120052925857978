import {gsap, Power2, Power3,} from "gsap";

let start = () => {
    return new Promise((resolve, reject) => {
        let burger = document.querySelector("#burger");
        let body = document.querySelector('body');
        let open = false;

        let burgerTl = gsap.timeline()
            .to('.line2', {width: '30px', ease: Power3.easeInOut, duration: 0.3})
            .to('.line3', {width: '30px', ease: Power3.easeInOut, duration: 0.3, delay: -0.2})
            .pause();

        burger.addEventListener('mouseenter', () => {
            if(open) return;
            burgerTl.play()
        });

        burger.addEventListener('mouseleave', () => {
            if(open) return;
            burgerTl.reverse()
        });

        let navTl = gsap
            .timeline()
            .to('nav', { translateY: 0, ease: Power2.easeInOut })
            .staggerTo(document.querySelectorAll('nav li'), 0.3, {opacity: 1, translateX: 0, ease: Power2.easeInOut}, 0.05)
            .add('anim')
            .to('.line1', {transformOrigin: 'left', rotate: '31', width: '35px', ease: Power3.easeInOut}, 'anim')
            .to('.line2', {opacity: 0, ease: Power3.easeInOut}, 'anim')
            .to('.line3', {transformOrigin: 'left', rotate: '-31', width: '35px', ease: Power3.easeInOut}, 'anim')
            .pause();

        burger.addEventListener('click', () => {
            if(open){
                navTl.timeScale(2.5);
                navTl.reverse()
                body.classList.remove('nav-open');
            }else{
                navTl.timeScale(1);
                navTl.play()
                body.classList.add('nav-open');
            }

            open = !open;
        })

        document.querySelectorAll('nav li a').forEach(el => {
            el.addEventListener('click', () => {
                navTl.timeScale(2.5);
                navTl.reverse()
                body.classList.remove('nav-open');
                open = false
            })
        })

        resolve()
    });
};

export default start;