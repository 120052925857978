import {gsap, Power2, Elastic, TimelineMax} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import start from "./realisation";
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.min'
let Flickity = require('flickity')
import 'flickity/dist/flickity.min.css'

gsap.registerPlugin(ScrollTrigger);

export default class Projet{
    async start(isHome = true){
        return new Promise((resolve, reject) => {
            let projets = document.querySelector('.projets');
            let zone_selector = document.querySelectorAll('.zone-selector');
            let index = 1
            zone_selector.forEach((el) => {
                const gif = document.querySelector('#circle-' + index)

                el.addEventListener('mouseenter', (e) => {    
                    gsap.fromTo(gif, {
                        scale: 0,
                        duration: .3,
                        zIndex: 1
                    },{
                        scale: 1,
                        duration: .3,
                        zIndex: 10
                    });
                });
                if(isHome) {
                    el.addEventListener('mouseover', (e) => {    
                        gsap.set(gif, {
                            left: e.clientX - 35,
                            top: e.pageY - 35,
                        });
                    });
                    el.addEventListener('mousemove', (e) => {
                        gsap.set(gif, {
                            scale: 1,
                            duration: .3,
                            left: e.clientX - 35,
                            top: e.pageY - 35,
                            zIndex: 10
                        });
                    });
                } else {
                    el.addEventListener('mouseover', (e) => {    
                        gsap.set(gif, {
                            left: e.clientX - 35,
                            top: e.pageY - 530,
                        });
                    });
                    el.addEventListener('mousemove', (e) => {
                        gsap.set(gif, {
                            scale: 1,
                            duration: .3,
                            left: e.clientX - 35,
                            top: e.pageY - 530,
                            zIndex: 10
                        });
                    });
                }
                el.addEventListener('mouseleave', (e) => {
                    gsap.fromTo(gif, {
                        scale: 1,
                        duration: .3,
                        zIndex: 10
                    },{
                        scale: 0,
                        duration: .3,
                        zIndex: 1
                    });
                    e.target.closest('.zone-selector').style.zIndex = 1;
                })
                index++
            });
    
            // let imageHeight = document.querySelector('#section-team .image .img').offsetHeight;
            // let textContainer = document.querySelector('#section-team .text-container');
            // let sectionHeight = document.querySelector('#section-team').offsetHeight
    
            // this.tl = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: "#section-team .image",
            //         markers: false,
            //         pin: true,   // pin the trigger element while active
            //         end: (sectionHeight - imageHeight - 60) + 'px',
            //         start: "top top", // when the top of the trigger hits the top of the viewport
            //         //end: '+=' + textContainer.offsetHeight + 'px', // end after scrolling 500px beyond the start
            //         //end: "bottom -150%",
            //         scrub: 1,    // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            //         invalidateOnRefresh: true
            //     }
            // })
    
            // this.tl2 = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: "#section-team .title",
            //         markers: false,
            //         endTrigger: "#section-team",
            //         end: sectionHeight + 'px',
            //         pin: true,   // pin the trigger element while active
            //         //end: '10px',
            //         start: "top top", // when the top of the trigger hits the top of the viewport
            //         //end: `+=${height}`,
            //         scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            //         invalidateOnRefresh: true
            //     }
            // })
    
            let lazyLoad = 2;


            if (window.matchMedia("(min-width: 768px)").matches) {
                lazyLoad = 2;
            }

            console.log(lazyLoad)
    
            this.flkty = new Flickity('.carousel', {
                "wrapAround": true,
                "pageDots": false,
                "prevNextButtons": false,
                lazyLoad
            });
    
            resolve();
        })
    }

    stop(){
        // this.tl.kill();
        // this.tl2.kill();
        this.flkty.destroy()
    }
}