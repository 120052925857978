import { gsap, Power2 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class Services {
    constructor() {
        this.timelines = {};
    }

    async start() {
        return new Promise((resolve, reject) => {
            let tabs = document.querySelectorAll('.tab');

            document.querySelectorAll('#section-services .tab-title').forEach(el => {
                let tab = el.closest('.tab');
                let name = tab.dataset.service;

                let tl = gsap.timeline()
                    .call(() => {
                        ScrollTrigger.refresh();
                    })
                    .fromTo(
                        window.innerWidth < 768 ? tab.querySelector('.arrow') : null,
                        { color: '#000', rotate: '47.5deg' },
                        { color: '#F2E286', rotate: '0deg', duration: 0.3, ease: Power2.easeInOut }
                    )                    
                    .to(tab.querySelector('.tab-title'), { opacity: 1, color: '#fed02f', duration: 0.3, delay: -0.1, ease: Power2.easeInOut })
                    .to(tab.querySelector('.tab-content'), { height: 'auto', duration: 0.5, delay: -0.2, ease: Power2.easeInOut })
                    .to(tab, { className: 'tab skew open', duration: 0 })
                    .call(() => {
                        ScrollTrigger.refresh();
                    })
                    .pause();

                this.timelines[name] = tl;

                el.addEventListener('click', () => {
                    let clickedTab = el.closest('.tab');
                    let clickedName = clickedTab.dataset.service;
                    this.timelines[clickedName].play();

                    let opened = document.querySelector('.tab.open');
                    if (opened) {
                        this.timelines[opened.dataset.service].reverse();
                    }
                });
            });

            resolve();
        });
    }

    stop() {
        Object.values(this.timelines).forEach(tl => tl.kill());

        console.log('stop services');
    }
}
