import {gsap, Power2, Expo, Power3, Elastic, TimelineMax} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Realisation from './realisation';
// import References from './references';
// import BgColor from './bgColor';
import Team from "./team";
import Projet from "./projet";
// import Contact from "./contact";
// import Approche from "./approche";
import Services from "./services";
import Blog from "./blog";

// let approche = new Approche;
let services = new Services;
let realisation = new Realisation;
let team = new Team;
let projet = new Projet;
// let references = new References;
// let contact = new Contact;
// let bgColor = new BgColor;
let blog = new Blog;

export default class Intro {
    start(full = true){
        window.scrollTo(0, 0);
        // let wordsHeight = document.querySelector(".words-wrapper").offsetHeight;

        this.tl = gsap
            .timeline()
            .set('header', {opacity: 0})
            // .set('.circle-cursor--outer', { scale: 0, opacity: 0.4})
            .call(() => {
                document.body.style.overflow = 'hidden';
            })
            .call(() => {
                let elements = document.querySelectorAll('.custom-lazyload');
                elements.forEach(el => {
                    let src = el.dataset.src;
                    el.src = src
                })
            });


        let dev = process.env.NODE_ENV === 'development';
        // if(!dev && full){
        //     this.tl.to('.words-wrapper', {opacity: 1});

        //     let duration = 0.25;
        //     document.querySelectorAll('#splash .word').forEach((el, id, array) => {
        //         if(id > 2){
        //             duration -= 0.015;
        //         }

        //         let ease = Power3.easeInOut;
        //         if(id === array.length-1){
        //             duration = 0.15;
        //             ease = Elastic.easeOut
        //         }

        //         this.tl.from(el, {top: wordsHeight + 'px', ease, duration: duration});

        //         //Dernier élement reste centré
        //         if(id === array.length-1){
        //             this.tl.to(el, {top: '0px', ease: Power3.easeInOut, duration: 1})
        //         }else{
        //             this.tl.to(el, {top: '-' + wordsHeight + 'px', ease: Power3.easeInOut, duration: duration})
        //         }
        //     });
        // }


        // let height = '80vh';
        // if (window.matchMedia("(min-width: 1200px)").matches) {
        //     height = '70vh';
        // }

        this.tl
            // .to('#splash', {height, opacity: 0, duration: 1, ease: Power3.easeInOut, delay: 0.3})
            // .to('#section-intro', {height, duration: 1, ease: Power3.easeInOut, delay: -1})
            .to('header', {opacity: 1, ease: Power3.easeInOut, delay: -0.5, duration: 1})
            .call( () => {
                // bgColor.start()

                Promise.all([
                    services.start(),
                    realisation.start(),
                    // approche.start(),
                    team.start(),
                    projet.start(),
                    // references.start(),
                    // contact.start(),
                    blog.start(),
                ])
                .then(values => {
                    ScrollTrigger.refresh()
                    document.body.style.overflow = 'inherit';

                    if(window.location.hash){
                        let section = document.querySelector(window.location.hash);
                        if(section){
                            section.scrollIntoView({ behavior: "smooth"})
                        }
                    }
                });
            })
            // .to('.circle-cursor--outer', { scale: 1, duration: 0.8, ease: Elastic.easeOut.config(1.1, 0.4)})

        gsap.timeline({
                scrollTrigger: {
                    trigger: "#section-intro",
                    markers: false,
                    pin: false,   // pin the trigger element while active
                    end: '30%',
                    start: "top top", // when the top of the trigger hits the top of the viewport
                    //end: "+=4000px", // end after scrolling 500px beyond the start
                    scrub: 1 // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                }
            }
        )
        // .to('#logo-text', {opacity: 0, ease: Power3.easeInOut})
    }

    stop(){
        this.tl.kill();
        // approche.stop();
        services.stop();
        realisation.stop();
        team.stop();
        projet.stop();
        // references.stop()
        // contact.stop()
        // bgColor.stop()
        blog.stop()
    }
}
