import {gsap, Power2, Elastic, TimelineMax} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import start from "./realisation";
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.min'
let Flickity = require('flickity')
import 'flickity/dist/flickity.min.css'

gsap.registerPlugin(ScrollTrigger);

export default class Team{
    async start(){
        return new Promise((resolve, reject) => {
            let peoples = document.querySelector('.peoples');
            let people = document.querySelectorAll('.people');
            const gif = document.querySelector('#gif')
            
            people.forEach((el) => {
                el.addEventListener('mouseover', (e) => {
                    if (!el.classList.contains('nogif')) {
                        let imageData = e.target.closest('.people').getAttribute('data-image');
                        e.target.closest('.people').style.zIndex = 99;
                        e.target.closest('.people').classList.add('selected');
                        gif.setAttribute('src', imageData)
                        peoples.classList.add('active');
        
                        gsap.set(gif, {
                            opacity: 1,
                            left: e.clientX,
                            top: e.pageY,
                            zIndex: 10
                        });
                    }
                });
                el.addEventListener('mousemove', (e) => {
                    gsap.set(gif, {
                        left: e.clientX,
                        top: e.pageY,
                    });
                });
                el.addEventListener('mouseleave', (e) => {
                    gsap.to(gif, {opacity: 0, duration: 0, zIndex: 1});
                    e.target.closest('.people').style.zIndex = 1;
                    e.target.closest('.people').classList.remove('selected');
                    peoples.classList.remove('active');
                })
            });
    
            // let imageHeight = document.querySelector('#section-team .image .img').offsetHeight;
            // let textContainer = document.querySelector('#section-team .text-container');
            // let sectionHeight = document.querySelector('#section-team').offsetHeight
    
            // this.tl = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: "#section-team .image",
            //         markers: false,
            //         pin: true,   // pin the trigger element while active
            //         end: (sectionHeight - imageHeight - 60) + 'px',
            //         start: "top top", // when the top of the trigger hits the top of the viewport
            //         //end: '+=' + textContainer.offsetHeight + 'px', // end after scrolling 500px beyond the start
            //         //end: "bottom -150%",
            //         scrub: 1,    // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            //         invalidateOnRefresh: true
            //     }
            // })
    
            // this.tl2 = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: "#section-team .title",
            //         markers: false,
            //         endTrigger: "#section-team",
            //         end: sectionHeight + 'px',
            //         pin: true,   // pin the trigger element while active
            //         //end: '10px',
            //         start: "top top", // when the top of the trigger hits the top of the viewport
            //         //end: `+=${height}`,
            //         scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            //         invalidateOnRefresh: true
            //     }
            // })
    
            let lazyLoad = 2;


            if (window.matchMedia("(min-width: 768px)").matches) {
                lazyLoad = 2;
            }

            console.log(lazyLoad)
    
            this.flkty = new Flickity('.carousel', {
                "wrapAround": true,
                "pageDots": false,
                "prevNextButtons": false,
                lazyLoad
            });
    
            resolve();
        })
    }

    stop(){
        // this.tl.kill();
        // this.tl2.kill();
        this.flkty.destroy()
    }
}