import {gsap, Power2, Elastic, TimelineMax, Linear} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import start from "./realisation";

gsap.registerPlugin(ScrollTrigger);

export default () => {
    // return new Promise((resolve, reject) => {

    //     let footerHeight = document.querySelector('#footer').offsetHeight;
    //     gsap.timeline({
    //             scrollTrigger: {
    //                 trigger: "#section-contact",
    //                 markers: false,
    //                 //endTrigger: "#section-services",
    //                 //end: `+=${height}`,
    //                 pin: false,   // pin the trigger element while active
    //                 end: '+=' + footerHeight + 'px',
    //                 start: "bottom bottom", // when the top of the trigger hits the top of the viewport

    //                 scrub: 0, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    //                 invalidateOnRefresh: true
    //             }
    //         }).to('.footer-container', {opacity: 1, translateY: 0, ease: Linear.easeNone})

    //     gsap.timeline({
    //         scrollTrigger: {
    //             trigger: "#section-contact",
    //             markers: false,
    //             //endTrigger: "#section-services",
    //             //end: `+=${height}`,
    //             pin: false,   // pin the trigger element while active
    //             start: "-500 top", // when the top of the trigger hits the top of the viewport
    //             end: '+=100',
    //             scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    //             invalidateOnRefresh: true
    //         }
    //     }).to('#footer', {opacity: 1, duration: 0.01})
    //     resolve()
    // })

}