import {gsap, Power2, Elastic, TimelineMax} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class Realisation{
    async start(){
        let sectionHeight = document.querySelector('#section-projets').offsetHeight;
        let imageHeight = document.querySelector('#section-projets .image').offsetHeight

        return new Promise((resolve, reject) => {

            this.tl = gsap.timeline({
                    
                })


            this.tl2 = gsap.timeline({
                
            })

            resolve()
        })
    }

    stop(){
        this.tl.kill()
        this.tl2.kill()

    }
}
