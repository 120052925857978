import './styles/app.scss'
import {Elastic, gsap, Power2} from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
// import Cursor from "./js/Cursor";
import './js/three.js'
import header from './js/header';
import footer from './js/footer';
import barba from '@barba/core';
import Intro from './js/intro';
import ArchiveRealisations from './js/archive-realisations';
import Blog from './js/blog';
import Projet from "./js/projet";

let titleElement = document.querySelector('.transition-title');

function delay(n) {
    n = n || 2000;
    return new Promise((done) => {
        setTimeout(() => {
            done();
        }, n);
    });
}

let intro = new Intro();
let projet = new Projet();
let archiveRealisations = new ArchiveRealisations();
let blog = new Blog();

gsap.set('.transition', {
    scaleX: 0,
    rotation: 10,
    xPercent: -5,
    yPercent: -50,
    transformOrigin: 'left center',
    autoAlpha: 1
});

function pageTransition(data) {
    gsap.fromTo('.transition',
    {
        rotation: 10,
        scaleX: 0,
        xPercent: -5
    },
    {
        duration: 0.8,
        xPercent: 0,
        scaleX: 1,
        rotation: 0,
        ease: 'power4.inOut',
        transformOrigin: 'left center'
    });

    gsap.set('.transition-title', {xPercent: -30, opacity: 0})

    gsap.to('.transition-title',
    {
        xPercent: 0,
        opacity: 0.2,
        ease: 'power4.inOut',
        delay: 0.3
    });

    setTimeout(() => {
        window.scroll({
            top: 0,
            left: 0,
        });
    }, 750)
}

function loadjscssfile(filename, filetype) {
    if (filetype === "js") {
        //if filename is a external JavaScript file
        const existingScript = document.querySelector(
            `script[src="${filename}"]`
        );
        if (existingScript) {
            existingScript.remove();
        }
        let fileref = document.createElement("script");
        fileref.setAttribute("type", "text/javascript");
        fileref.setAttribute("src", filename);

        if (typeof fileref !== "undefined"){
            document.getElementsByTagName("head")[0].appendChild(fileref);
        }
    }
    else if (filetype === "css") {
        //if filename is an external CSS file
        const existingCSS = document.querySelector(`link[href='${filename}']`);
        if (existingCSS) {
            existingCSS.remove();
        }
        let fileref = document.createElement("link");
        fileref.setAttribute("rel", "stylesheet");
        fileref.setAttribute("type", "text/css");
        fileref.setAttribute("href", filename);

        if (typeof fileref !== "undefined"){
            document.getElementsByTagName("head")[0].appendChild(fileref);
        }
    }

}

function setBodyClass(data){
    try{
        let response = data.next.html.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', data.next.html)

        let el = document.createElement('html');
        el.innerHTML = response;

        let notbody = el.querySelector('notbody');

        let bodyClasses = notbody.classList;

        document.querySelector('body').classList = bodyClasses


            //Supprime le css elementor de la page précédente
            if(data.current.container.querySelector('.elementor')){
                let currentPageId = data.current.container
                    .querySelector(".elementor")
                    .getAttribute("data-elementor-id");

                let oldScriptURL = "/wp-content/uploads/elementor/css/post-" + currentPageId + ".css";

                const oldElementorScript = document.querySelector(
                    'link[src="' + oldScriptURL + '"]'
                );

                if (oldElementorScript) {
                    oldElementorScript.remove();
                }
            }

            if(data.next.container.querySelector('.elementor')){
                let nextPageId = data.next.container
                    .querySelector(".elementor")
                    .getAttribute("data-elementor-id");

                let newScriptURL = "/wp-content/uploads/elementor/css/post-" + nextPageId + ".css"; // Add this for cache fix: ?cachebuster=' + new Date().getTime()
                loadjscssfile(newScriptURL, "css");
            }

    }catch (e) {
        console.error(e)
    }
}

function captureLinkClick(){
    document.querySelectorAll('a').forEach(el => {
        el.addEventListener('click', e => {
            let title = el.dataset.title;

            if(title) {
                document.querySelector('.transition-title').innerText = title;
            }else{
                document.querySelector('.transition-title').innerText = '';
            }
        })
    })
}
captureLinkClick()

// barba.init({
//     timeout: 10000,
//     debug: true,
//     transitions: [{
//         name: 'transit',
//         async leave(data) {
//                 const done = this.async();

//                 pageTransition(data, 'hello');
//                 await delay(1500);

//                 if(data.current.namespace === 'archive-realisations'){
//                     archiveRealisations.stop();
//                 }

//                 if(data.current.namespace === 'home'){
//                     intro.stop();
//                 }

//                 done();
//         },

//         async enter(data) {
//             gsap.to('.transition', {
//                 duration: 0.8,
//                 scaleX: 0,
//                 xPercent: 5,
//                 rotation: -10,
//                 transformOrigin: 'right center',
//                 ease: 'power4.inOut'
//             });

//             gsap.fromTo('.transition-title',
//             {
//                 opacity: 0.2,
//                 xPercent: 0,
//             },
//             {
//                 opacity: 0,
//                 xPercent: 20,
//                 ease: 'power4.inOut',
//             });

//             if(data.next.namespace === "archive-realisations"){
//                 archiveRealisations.start();
//             }

//             if(data.next.namespace === 'home'){
//                 intro.start(false)
//             }

//             captureLinkClick()

//             setTimeout(() => {
//                 ScrollTrigger.refresh()
//             }, 500)
//         },

//         async once(data) {
//             //console.log('once', data)
//         },

//         async beforeEnter(data){
//             setBodyClass(data)

//             if (window.elementorFrontend) {
//                 setTimeout(() => {
//                     window.elementorFrontend.init()

//                     // ALLOW ELEMENTOR VIDEOS TO AUTOPLAY AFTER TRANSITION
//                     let elementorVideos = document.querySelectorAll(".elementor-video");
//                     if (typeof elementorVideos != "undefined" && elementorVideos != null) {
//                         elementorVideos.forEach((video) => {
//                             video.play();
//                         });
//                     }

//                 }, 50)

//             }
//         }
//     },
//     // {
//     //     name: 'from-index-to-single-realisation',
//     //     from: {
//     //         namespace: [
//     //             'archive-realisations'
//     //           ]
//     //     },
//     //     to: {
//     //         namespace: [
//     //             'single-realisation'
//     //         ]
//     //     },
//     //     enter(data){
//     //         console.log('hello')
//     //     },
//     //     async beforeEnter(data){
//     //         setBodyClass(data)
//     //     }
//     // }
//     ]
// });

// barba.hooks.after(() => {
//     ga('set', 'page', window.location.pathname);
//     ga('send', 'pageview');
// });


header();
footer();

//Home
if(document.querySelector('body.home')){
    intro.start(true);
    let inputs = document.querySelectorAll('input');
    for (let i = 0; i < inputs.length; i++) {
        inputs[i].placeholder = ' ';
    }
}
if(document.querySelector('body.page-id-1151')){
    projet.start(false);
}

if(document.querySelector('body.page-template-index-realisations')){
    archiveRealisations.start();
}


// let s = document.querySelector('#icon .s');
// let icon = document.querySelector('#icon')
// let animRunning = false;


// if(icon){
//     icon.addEventListener('mouseenter', () => {
//         if (animRunning) {
//             return;
//         }
//         animRunning = true;
//         let all = document.querySelectorAll('.s *');
//         gsap.timeline()
//             .staggerTo(all, 0.3, {opacity: 0, ease: Power2.easeInOut}, 0.05)
//             .to(icon, {rotateZ: 360, ease: Elastic.easeInOut, duration: 1.5})
//             .staggerTo(all, 0.8, {opacity: 1, ease: Elastic.easeInOut}, 0.015)
//             .set(icon, {rotateZ: 0})
//             .call(() => {
//                 animRunning = false
//             })
//     })
// }


// new Cursor();

let skew = false

if (skew) {

    let proxy = {skew: 0},
        skewSetter = gsap.quickSetter(".skew", "skewY", "deg"), // fast
        clamp = gsap.utils.clamp(-2, 2); // don't let the skew go beyond 20 degrees.

    ScrollTrigger.create({
        onUpdate: (self) => {
            let skew = clamp(self.getVelocity() / -300);
            // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
            if (Math.abs(skew) > Math.abs(proxy.skew)) {
                proxy.skew = skew;
                gsap.to(proxy, {
                    skew: 0,
                    duration: 0.8,
                    ease: "power3",
                    overwrite: true,
                    onUpdate: () => skewSetter(proxy.skew)
                });
            }
        }
    });

// make the right edge "stick" to the scroll bar. force3D: true improves performance
    gsap.set(".skew", {transformOrigin: "right center", force3D: true});
}
